<template>
  <div class="m-taomlar m-buyurtma m-settings">
    <div class="container">
      <div class="row custom-row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 custom-col2">
          <dashboard></dashboard>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12 custom-col2">
          <div class="settings-card">
            <form class="prof-form">
              <div class="img-sec">
                <div class="prof-av">
                  <div class="av-img">
                    <input type="file" />
                    <img src="../../../assets/images/icons/prof.svg" alt="" />
                    <span>Avatar</span>
                  </div>
                  <div class="prof-info">
                    <div class="p-name">{{ $t("Qazi markazi") }}</div>
                    <div class="p-sub">{{ $t("Cheef") }}</div>
                  </div>
                </div>
                <div class="links">
                  <div class="check-box">
                    <label class="chekcbox-con"
                      >{{ $t("Yetkazib berish xizmati") }}
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <a href="#!" class="for-delete">{{
                    $t("Profilni o‘chirish")
                  }}</a>
                </div>
              </div>
              <div class="info-sec">
                <div class="info-cards">
                  <label>{{ $t("Nomi") }}</label>
                  <input type="text" />
                </div>
                <div class="info-cards">
                  <label>{{ $t("Javobgar jismoniy shaxs") }}</label>
                  <input type="text" />
                </div>
                <div class="info-cards">
                  <label>{{ $t("Aloqa telefon raqami") }}</label>
                  <input type="text" />
                </div>
                <div class="info-link-cards">
                  <div
                    class="link"
                    @click="social = 'facebook'"
                    :class="{ linked: facebook.length > 0 }"
                  >
                    <img src="../../../assets/images/icons/fcb.svg" alt="" />
                    <div
                      class="linking-apps"
                      :class="{ active: social == 'facebook' }"
                    >
                      <label
                        >In publishing and graphic design, Lorem ipsum is a
                        placeholder text...</label
                      >
                      <div class="inputs">
                        <input
                          v-model="facebook"
                          type="text"
                          class="in-text"
                          :placeholder="$t('Facebook link')"
                        />
                        <button class="in-btn">
                          <img
                            src="../../../assets/images/icons/true.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="link"
                    @click="social = 'instagram'"
                    :class="{ linked: instagram.length > 0 }"
                  >
                    <img src="../../../assets/images/icons/ins.svg" alt="" />
                    <div
                      class="linking-apps"
                      :class="{ active: social == 'instagram' }"
                    >
                      <label
                        >In publishing and graphic design, Lorem ipsum is a
                        placeholder text...</label
                      >
                      <div class="inputs">
                        <input
                          v-model="instagram"
                          type="text"
                          class="in-text"
                          :placeholder="$t('Instagram link')"
                        />
                        <button class="in-btn">
                          <img
                            src="../../../assets/images/icons/true.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="link"
                    @click="social = 'telegram'"
                    :class="{ linked: telegram.length > 0 }"
                  >
                    <img src="../../../assets/images/icons/tlg.svg" alt="" />
                    <div
                      class="linking-apps"
                      :class="{ active: social == 'telegram' }"
                    >
                      <label
                        >In publishing and graphic design, Lorem ipsum is a
                        placeholder text...</label
                      >
                      <div class="inputs">
                        <input
                          type="text"
                          v-model="telegram"
                          class="in-text"
                          :placeholder="$t('Telegram link')"
                        />
                        <button class="in-btn">
                          <img
                            src="../../../assets/images/icons/true.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="link">
                    <img src="../../../assets/images/icons/fcb.svg" alt="" />
                    <div class="linking-apps">
                      <label
                        >In publishing and graphic design, Lorem ipsum is a
                        placeholder text...</label
                      >
                      <div class="inputs">
                        <input
                          type="text"
                          class="in-text"
                          :placeholder="$t('Facebook link')"
                        />
                        <button class="in-btn">
                          <img
                            src="../../../assets/images/icons/true.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-cards info-cards-map">
                  <label>{{ $t("Manzil") }}</label>
                  <div class="left">
                    <label for="location" class="navbar-select">
                      <div class="loc-img">
                        <img
                          src="../../../assets/images/icons/loc2.svg"
                          alt=""
                        />
                      </div>
                      <div class="my-select">
                        <div class="custom-select">
                          <select>
                            <option value="">
                              {{ $t("Yunusobod tum. Farovon 2-tor kocha 65A") }}
                            </option>
                            <option value="">
                              {{ $t(" Lorem, ipsum dolor. tanlang1") }}
                            </option>
                            <option value="">
                              {{ $t("Lorem ipsum dolor sit. tanlang2") }}
                            </option>
                            <option value="">
                              {{ $t("Lorem, ipsum. tanlang3") }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="info-cards info-buttons">
                  <div class="buttons">
                    <input
                      type="button"
                      :value="$t('Bekor qilish')"
                      class="cancel-btn"
                    />
                    <input
                      type="submit"
                      :value="$t('Saqlash')"
                      class="submit-btn"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from "../../main/Dashboard.vue";
export default {
  components: { Dashboard },
  data() {
    return {
      social: "",
      telegram: "",
      instagram: "",
      facebook: "",
    };
  },
};
</script>

<style>
</style>